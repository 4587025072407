<style lang="scss">
.code {
  code {
    background-color: white !important;
    padding: 5px;
    font-size: 105%;
    min-height: 200px;
  }
}
</style>
<template>
  <v-card>
    <v-card-title>
      {{ log.action }}
      <span class="text--disabled ml-2">#{{ log.id || "?" }}</span>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-tabs v-model="tab">
      <v-tab>Geral</v-tab>
      <v-tab>Request</v-tab>
      <v-tab>Response</v-tab>
      <v-tab>Metadata</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Ação </v-list-item-title>
                <v-list-item-subtitle>
                  {{ log.action }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Criado em </v-list-item-title>
                <v-list-item-subtitle>
                  {{ log.created_at }} • {{ log.created_at_diff }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Executado em </v-list-item-title>
                <v-list-item-subtitle>
                  {{ log.executed_at }} • {{ log.executed_at_diff }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Status </v-list-item-title>
                <v-list-item-subtitle>
                  {{ log.success ? "Sucesso" : "Falha" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-tab-item>
      <v-tab-item
        style="white-space: pre-wrap; max-height: 70vh; overflow-y: auto"
      >
        <v-card-text class="code">
          <v-btn
            @click="copyCalleeLink(request)"
            absolute
            top
            right
            style="right: 40px"
            icon
            :loading="request_loading"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <pre v-highlightjs="request"><code class="xml"></code></pre>
        </v-card-text>
      </v-tab-item>
      <v-tab-item style="white-space: pre-wrap; height: 70vh; overflow-y: auto">
        <v-card-text class="code">
          <v-btn
            @click="copyCalleeLink(response)"
            absolute
            top
            right
            style="right: 40px"
            icon
            :loading="response_loading"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <pre v-highlightjs="response"><code class="xml"></code></pre>
        </v-card-text>
      </v-tab-item>
      <v-tab-item style="white-space: pre-wrap; height: 70vh; overflow-y: auto">
        <v-card-text class="code">
          <v-btn
            @click="copyCalleeLink(log.metadata)"
            absolute
            top
            right
            style="right: 40px"
            icon
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <code class="javascript">
            {{ log.metadata }}
          </code>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import beautify from "xml-beautifier";
import { formatDateTime } from "@/tools/date";
import {
  showNotificationLogRequest,
  showNotificationLogResponse,
} from "@/services/eps";

export default {
  data() {
    return {
      tab: 0,
      log: {},
      text_to_copy: "",
      request: "",
      response: "",
      request_loading: false,
      response_loading: false,
      request_loaded: false,
      response_loaded: false,
    };
  },
  watch: {
    tab(value) {
      if (value === 1) {
        //request
        if (this.request_loaded) {
          return;
        }

        this.request_loaded = true;
        this.getRequest();
        return;
      }

      if (value === 2) {
        //response
        if (this.response_loaded) {
          return;
        }

        this.response_loaded = true;
        this.getResponse();
        return;
      }
    },
  },
  methods: {
    formatLongDateWithDuration(raw_date) {
      const date = this.$moment(raw_date);
      const duration = this.$moment
        .duration(date.diff(this.$moment()))
        .humanize(true);

      return `${formatDateTime(raw_date)} • ${duration}`;
    },
    formatLongDate(raw_date) {
      return formatDateTime(raw_date);
    },
    fillData(log) {
      this.log = {
        ...log,
      };
      this.tab = 0;

      this.request = "";
      this.response = "";
      this.request_loaded = false;
      this.response_loaded = false;
    },
    format(xml) {
      if (!xml) return;
      return beautify(xml);
    },
    async copyCalleeLink(text) {
      navigator.clipboard.writeText(text);
      this.$store.commit("sendMessage", {
        text: `Texto copiado`,
      });
    },
    async getRequest() {
      this.request_loading = true;
      const response = await showNotificationLogRequest(this.log.id);
      this.request = response ? beautify(response) : "";
      this.request_loading = false;
    },
    async getResponse() {
      this.response_loading = true;
      const response = await showNotificationLogResponse(this.log.id);
      this.response = response ? beautify(response) : "";
      this.response_loading = false;
    },
  },
};
</script>
