<template>
    <v-menu v-model="menu">
        <template v-slot:activator="{on, attrs}">
            <v-btn v-bind="{...attrs, ...$attrs}" v-on="on" :loading="loading">Alterar utilizador EPS</v-btn>
        </template>

        <v-list>
            <v-list-item 
                v-for="user in users" :key="user.slug"
                @click="handleChangeUser(user)"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        {{ user.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { updateEpsUser } from "@/services/eps.js";

export default {
    props: {
        lead_identifier_code: String,
    },

    data() {
        return {
            menu: false,
            loading: false,
            users: [
                {
                    slug: 'general',
                    name: 'Geral',
                },
                {
                    slug: 'traditional_expertise',
                    name: 'Peritagem Tradicional',
                }
            ]
        }
    },

    methods: {
        async handleChangeUser(user) {
            this.menu = false;
            this.loading = true;

            await updateEpsUser({
                lead_identifier_code: this.lead_identifier_code,
                eps_user_slug: user.slug
            });

            this.$emit('changed', user);

            this.loading = false;
        }
    }
}
</script>