<template>
  <div>
    <v-container v-if="notification.id">
      <v-row class="mb-3 mt-5">
        <v-col cols="4">
          <v-btn to="/eps" exact text color="primary">
            <v-icon left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
        </v-col>
        <v-col v-if="actions" class="text-right">
          <v-btn
            @click="getData()"
            color="info"
            :loading="loading"
            class="ml-5"
          >
            <v-icon left>mdi-refresh</v-icon>
            Atualizar
          </v-btn>

          <v-btn
            :disabled="!actions.can_unblock"
            :dark="actions.can_unblock"
            @click="unlockNotification()"
            :loading="reprocessing"
            color="green"
            class="ml-2"
          >
            <v-icon size="17" left>mdi-lock-open</v-icon> Desbloquear
          </v-btn>

          <v-btn
            @click="blockNotification()"
            :disabled="!actions.can_block"
            :dark="actions.can_block"
            :loading="reprocessing"
            color="orange"
            class="ml-2"
          >
            <v-icon size="17" left>mdi-lock</v-icon> Bloquear
          </v-btn>

          <v-btn
            @click="deleteNotification()"
            :disabled="!actions.can_delete"
            :dark="actions.can_delete"
            color="red"
            class="ml-2"
          >
            <v-icon size="17" left>mdi-trash-can</v-icon> Excluir
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card v-if="getStatus() == 'blocked'" color="red" dark>
            <v-toolbar @click="expand_error = !expand_error" color="red">
              <v-list-item-title>
                <v-icon left>mdi-lock</v-icon> Erro ao processar
              </v-list-item-title>
              <v-list-item-action>
                <v-icon>mdi-chevron-down</v-icon>
              </v-list-item-action>
            </v-toolbar>
            <v-card-text v-if="expand_error">
              <v-alert color="red darken-4">
                {{ notification.block_reason }}
              </v-alert>
            </v-card-text>
          </v-card>
          <v-alert
            v-else-if="getStatus()"
            :icon="getNotificationIcon()"
            :color="getNotificationColor()"
            dark
          >
            <strong>Status: </strong> {{ getNotificationStatusLabel() }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="7" lg="5">
          <v-card outlined>
            <v-card-title>
              <v-icon color="red" v-if="notification.inbound" left
                >mdi-arrow-up</v-icon
              >
              <v-icon color="green" v-else left>mdi-arrow-down</v-icon>
              {{ notification.eps_action?.name || notification.action }}
              <span class="text--disabled ml-2">#{{ id }}</span>
            </v-card-title>

            <v-card-subtitle class="mt-1">
              <v-chip
                :to="`/leads/${notification.lead.identifier_code}/integration`"
                color="info"
                small
                label
                v-if="notification.lead"
              >
                {{ notification.lead.identifier_code }}
                <v-icon right small>mdi-open-in-new</v-icon>
              </v-chip>

              <v-chip small label v-else-if="notification.parent_entity_id">
                {{ notification.parent_entity_id }}
              </v-chip>
            </v-card-subtitle>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Ação</v-list-item-subtitle>
                  <v-simple-table>
                    <tr>
                      <th class="text-left" width="100px">Nome</th>
                      <td>{{ notification.eps_action?.name || "???" }}</td>
                    </tr>
                    <tr>
                      <th class="text-left" width="100px">Código EPS</th>
                      <td>{{ notification.action || "???" }}</td>
                    </tr>
                    <tr>
                      <th class="text-left" width="100px">Canonical</th>
                      <td>{{ notification.eps_action?.canonical || "???" }}</td>
                    </tr>
                  </v-simple-table>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Usuário EPS </v-list-item-subtitle>
                  <v-simple-table>
                    <tr>
                      <th class="text-left" width="100px">Nome</th>
                      <td>{{ notification.eps_user?.name || "Default" }}</td>
                    </tr>
                    <tr>
                      <th class="text-left" width="100px">Slug</th>
                      <td>{{ notification.eps_user?.slug || "Default" }}</td>
                    </tr>
                  </v-simple-table>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <UpdateEpsUserButton
                  :disabled="notification.executed_at"
                  :lead_identifier_code="notification.parent_entity_id"
                  small
                  color="primary"
                  @changed="fillEpsUser"
                ></UpdateEpsUserButton>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Objeto referenciado
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.entity_id }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Objeto pai</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.parent_entity_id }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Código do Motivo</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ getMotives(notification.motives).Code || "Não há" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Descrição do Motivo</v-list-item-subtitle
                  >
                  <v-list-item-title>
                    {{
                      getMotives(notification.motives).MotiveDescription ||
                      "Não há"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Justificativa</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.justification || "N/A" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Criado por</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.sent_by || "Sistema" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Criado em</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.created_at }} •
                    {{ notification.created_at_diff }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Última alteração</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ notification.updated_at }} •
                    {{ notification.updated_at_diff }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Executado em</v-list-item-subtitle>
                  <v-list-item-title v-if="notification.executed_at">
                    {{ notification.executed_at }} •
                    {{ notification.executed_at_diff }}
                  </v-list-item-title>
                  <v-list-item-title v-else> Não executado </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-title> Logs da Integração </v-card-title>

            <v-list
              v-if="notification.logs.length > 0"
              style="overflow-y: auto"
            >
              <div v-for="(log, index) in notification.logs" :key="log.id">
                <v-list-item @click="showIntegrationLog(log)">
                  <v-list-item-action>
                    <v-chip v-if="log.success" small color="green" dark label>
                      Sucesso
                    </v-chip>
                    <v-chip v-else small color="red" dark label> Erro </v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.action }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ log.created_at }} • {{ log.created_at_diff }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < notification.logs.length"
                ></v-divider>
              </div>
            </v-list>
            <EmptyState v-else message="Nenhum registro encontrado" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="integration_log_dialog" max-width="950" scrollable eager>
      <EpsIntegrationLogViewer
        ref="integration_log_viewer"
        @close="integration_log_dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  showNotification,
  unblockNotification,
  blockNotification,
} from "@/services/eps";
import { formatDateTime } from "@/tools/date";

import EpsIntegrationLogViewer from "@/components/EPS/EpsIntegrationLogViewer";
import EmptyState from "@/components/EmptyState";
import UpdateEpsUserButton from "../components/EPS/UpdateEpsUserButton.vue";

export default {
  components: { EpsIntegrationLogViewer, EmptyState, UpdateEpsUserButton },
  data() {
    const id = this.$route.params["id"];
    return {
      id,
      notification: {},
      actions: {},
      expand_error: false,
      integration_log_dialog: false,
      reprocessing: false,
      poll: null,
      loading: false,
    };
  },
  methods: {
    getMotives(motives) {
      if (!motives) return {};

      if (typeof motives == "string") {
        return JSON.parse(motives);
      } else {
        return motives;
      }
    },
    formatLongDateWithDuration(raw_date) {
      const date = this.$moment(raw_date);
      const duration = this.$moment
        .duration(date.diff(this.$moment()))
        .humanize(true);

      return `${formatDateTime(raw_date)} • ${duration}`;
    },
    formatLongDate(raw_date) {
      return formatDateTime(raw_date);
    },
    showIntegrationLog(log) {
      this.$refs.integration_log_viewer.fillData(log);
      this.integration_log_dialog = true;
    },
    async deleteNotification() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Excluir notificação?",
        description: "A ação não poderá ser desfeita.",
        positive_action: async () => {
          try {
            await this.$http.delete(`/leads/eps_notifications/${this.id}`);
            this.$router.go(-1);
          } catch (error) {
            this.$store.commit("sendMessage", {
              text: error,
              color: "red",
            });
          }
        },
      });
    },
    async unlockNotification() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Desbloquear notificação?",
        description: "A notificação voltará para a fila de processamento.",
        positive_action: async () => {
          try {
            this.reprocessing = true;
            await unblockNotification(this.id);

            this.getData();
            this.reprocessing = false;
          } catch (error) {
            this.$store.commit("sendMessage", {
              text: error,
              color: "red",
            });
          }
        },
      });
    },
    async blockNotification() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Bloquear notificação?",
        description: "A notificação será bloqueada e não será executada.",
        positive_action: async () => {
          try {
            this.reprocessing = true;
            await blockNotification(this.id);

            this.getData();
            this.reprocessing = false;
          } catch (error) {
            this.$store.commit("sendMessage", {
              text: error,
              color: "red",
            });
          }
        },
      });
    },
    getNotificationExecutedAtLabel(notification) {
      switch (this.getStatus()) {
        case "executed":
          return (
            "Executado em " +
            this.$moment(notification.executed_at).format("DD/MM/YYYY HH:mm:ss")
          );
        case "ignored":
          return "Não será executada até resolução de problemas anteriores";
        case "in_progress":
          return "Aguardando motor de integração";
        default:
          return null;
      }
    },
    getNotificationStatusLabel() {
      switch (this.getStatus()) {
        case "blocked":
          return "Bloqueado";
        case "executed":
          return "Executado";
        case "ignored":
          return "Não será executada até resolução de problemas anteriores";
        case "in_progress":
          return "Em execução";
        default:
          return null;
      }
    },
    getNotificationIcon() {
      switch (this.getStatus()) {
        case "blocked":
          return "mdi-alert";
        case "executed":
          return "mdi-check";
        case "ignored":
          return "mdi-pause";
        case "in_progress":
          return "mdi-play";
        default:
          return null;
      }
    },
    getNotificationColor() {
      switch (this.getStatus()) {
        case "blocked":
          return "red";
        case "executed":
          return "success";
        case "in_progress":
          return "info";
        default:
          return "grey";
      }
    },
    async getData(silent = true) {
      this.loading = true;
      if (silent === false) {
        this.$store.commit("setLoading", {
          loading: true,
        });
      }
      const { data, meta } = await showNotification(this.id);
      this.notification = data;
      this.actions = meta.actions;

      this.$store.commit("setLoading", {
        loading: false,
      });
      this.loading = false;
    },
    getMotiveData() {
      if (!this.notification.motives) return;

      const data = JSON.parse(this.notification.motives);
      return data;
    },
    getStatus() {
      if (!this.notification) return;

      if (this.notification.blocked) return "blocked";
      else if (this.notification.executed_at) return "executed";
      else if (this.notification.ignored) return "ignored";
      else return "in_progress";
    },
    fillEpsUser(epsUser) {
      this.notification = {
        ...this.notification,
        eps_user: epsUser
      }
    }
  },
  created() {
    this.getData(false);
  },
};
</script>
